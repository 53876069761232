import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Particles from "react-tsparticles";
import './App.css';
import colorExplosion from "./tsparticles/colorExplosion";
import confetti from "./tsparticles/confetti";
import fire from "./tsparticles/fire";
import fireworks from "./tsparticles/fireworks";
import skypack from "./tsparticles/skypack";

function App() {

  useEffect(() => {
    ReactGA.pageview('home');
  });

  const particleOptions = [
    colorExplosion,
    confetti,
    fire,
    fireworks,
    skypack
  ];
  const particleOption = particleOptions[Math.floor(Math.random() * particleOptions.length)];

  return (
    <div className="app">
      <Particles 
        id="tsparticles"
        options={particleOption}        
      />      
      <section className="container">
        <div className="justin flex-center">
          <img alt="Justin Ireland" className="" src="/assets/justin.png" />
          <h1 className="flex-center">Justin Ireland</h1>
        </div>
        <div className="about flex-center column">
          <div className="links flex-center column">
            <div className="flex-center row">
              <a href="https://www.linkedin.com/in/justinireland" target="_blank" rel="noopener noreferrer">
                <img alt="LinkedIn" src="/assets/linkedin.png" />
              </a>
              <a href="https://instagram.com/justin_ireland" target="_blank" rel="noopener noreferrer">
                <img alt="Instagram" src="/assets/instagram.png" />
              </a>
              <a href="https://github.com/justinireland" target="_blank" rel="noopener noreferrer">
                <img alt="Github" src="/assets/github.png" />
              </a>
            </div>
          </div>         
        </div>
      </section>
    </div>
  )
}

export default App;
